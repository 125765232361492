footer {
  z-index: 1;
  background-color: black;
  position: static;
  bottom: 0;
  margin-top: auto;
  padding: 8px;
  text-align: center;
  width: 100%;
  height: 2.5rem;            /* Footer height */
  color: white;
}




