@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';

@import './components/PageHeader/PageHeader.scss';
@import './components/PageFooter/PageFooter.scss';
@import './content/LandingPage/LandingPage.scss';

html, body {
    height: 100%;
    width: 100%;
}

.wrapper {
    position: relative;       
    height: auto;
    margin: 0 auto 2.5rem; /* footer height + space */
    min-height: 100%;
    box-sizing: border-box;
    padding-bottom: 2.5rem;
}

